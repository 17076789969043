import { FormGroup, ValidatorFn } from '@angular/forms';

export interface FormValidators {
  [key: string]: ValidatorFn[];
}

export function addFormValidators(
  form: FormGroup,
  validators: FormValidators
): void {
  for (let key in validators) {
    form.get(key).addValidators(validators[key]);
    form.get(key).updateValueAndValidity();
  }
}

export function removeFormValidators(form: FormGroup): void {
  for (let key in form.controls) {
    const control = form.get(key);
    control.clearValidators();
  }
}
