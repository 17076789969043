@if (selectedOption) {
  <span class="label">
    {{ label }}
    @if (markedAsRequired) {
      *
    }
  </span>
}

<div
  class="input-container"
  [ngClass]="{
    'input-container_opened': optionsOpened,
    'input-container_error': error,
  }">
  <input
    #input
    class="input t-heading-6"
    [formControl]="dropdownControl"
    [placeholder]="label"
    (click)="openOptionList()"
    (focus)="input.blur()"
    tabindex="-1" />

  <svg-icon
    class="arrow"
    [class.arrow_up]="optionsOpened"
    key="dropdown-arrow"
    size="xl"></svg-icon>
</div>

@if (optionsOpened) {
  <div class="options-container">
    @for (option of options; track $index; let last = $last) {
      <div class="option t-heading-6" (click)="selectOption(option, last)">
        <ng-container
          *ngTemplateOutlet="itemRef; context: { $implicit: option }">
        </ng-container>
      </div>
    }
  </div>
}

<span class="error">{{ error }}</span>
