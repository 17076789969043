import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { take } from 'rxjs';

import { BaseControlDirective } from '../base-control.directive';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['../controls-styles.scss', './dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, NgTemplateOutlet, SvgIconComponent, NgClass],
})
export class DropdownComponent extends BaseControlDirective {
  @HostListener('document:click', ['$event'])
  public onClickOutside(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  @Input() public labelField = '';

  @Input() public valueField = '';

  @Input() public options: any[] = [];

  @Output() public lastOptionChosen = new EventEmitter<any>();

  @ContentChild('item') public itemRef!: TemplateRef<any>;

  public selectedOption: any;

  public optionsOpened: boolean = false;

  private elementRef = inject(ElementRef<DropdownComponent>);

  public dropdownControl = new UntypedFormControl(null);

  public override ngOnInit() {
    super.ngOnInit();
    this.setInitialSelectedOption();
  }

  public selectOption(option: Record<string, string>, isLast: boolean): void {
    this.selectedOption = option;
    this.formControl.setValue(option[this.valueField] || option);
    this.setSelectedValue();
    this.lastOptionChosen.emit(isLast);
    this.closeOptionList();
  }

  public openOptionList(): void {
    this.optionsOpened = true;
  }

  public closeOptionList(): void {
    this.optionsOpened = false;
  }

  private setInitialSelectedOption() {
    if (!this.formControl.value) {
      this.formControl.valueChanges.pipe(take(1)).subscribe(value => {
        this.selectedOption = this.findOption(value);
        this.setSelectedValue();
      });
    } else {
      this.selectedOption = this.findOption(this.formControl.value);
      this.setSelectedValue();
    }
  }

  private setSelectedValue() {
    this.dropdownControl.setValue(
      this.selectedOption['label'] || this.selectedOption
    );
  }

  private findOption(value: any): any {
    return this.options.find(option => option[this.valueField] === value);
  }
}
